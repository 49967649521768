/* global _paq */

function ImgSearch() {
    var search_offset = 0;
    var img_at_a_time = 25;
    var flash_container = document.getElementById('FlashMsg');
    var template_cache = {};
    var search_term_el = document.getElementById('SearchTerm');
    var public_domain_el = document.getElementById('PublicDomain');
    var load_more_el = document.getElementById('LoadMore');
    var img_skeletons = '';
    var result_container = document.getElementById('ImgResults');
    var scroll_up = document.getElementById('ScrollUp');

    // function node_list_to_array(nl) {
    //     var arr = [];
    //     var node_count = nl.length;
    //
    //     for (var i = 0; i != node_count; i++) {
    //         arr[i] = nl[i];
    //     }
    // }

    // john resig's micro templating
    function render_template(tpl_id, data) {
        // Figure out if we're getting a template, or if we need to
        // load the template - and be sure to cache the result.
        var fn = !/\W/.test(tpl_id) ?
            template_cache[tpl_id] = template_cache[tpl_id] ||
            render_template(document.getElementById(tpl_id).innerHTML.trim()) :
            // Generate a reusable function that will serve as a template
            // generator (and which will be cached).
            new Function("obj",
                "var p=[],print=function(){p.push.apply(p,arguments);};" +

                // Introduce the data as local variables using with(){}
                "with(obj){p.push('" +

                // Convert the template into pure JavaScript
                tpl_id.replace(/[\r\t\n]/g, " ")
                   .replace(/'(?=[^%]*%>)/g,"\t")
                   .split("'").join("\\'")
                   .split("\t").join("'")
                   .replace(/<%=(.+?)%>/g, "',$1,'")
                   .split("<%").join("');")
                   .split("%>").join("p.push('")
                   + "');}return p.join('');");

        // Provide some basic currying to the user
        /// returns the function if not called with `data`
        return data ? fn(data) : fn;
    }

    function flash_msg(msg) {
        flash_container.innerHTML = msg;
    }

    function get_search_term() {
        var search_term = search_term_el.value.trim();
        if (search_term.length == 0) {
            flash_msg('Please enter a search term.');

            return;
        }
        if (public_domain_el.checked) {
            search_term = 'public domain ' + search_term;
        }

        return encodeURIComponent(search_term);
    }

    function control_load_more(show_or_hide) {
        if (show_or_hide == 'show') {
            load_more_el.classList.add('show');
        }
        else {
            load_more_el.classList.remove('show');
        }
    }

    function control_scroll_up(show_or_hide) {
        if (show_or_hide == 'show') {
            scroll_up.classList.add('show');
        }
        else {
            scroll_up.classList.remove('show');
        }
    }

    function add_image_skeletons() {
        if (img_skeletons.length == 0) {
            var i = 35;
            var skeleton = render_template('ImgSkeletonTpl', {});
            while (i > 0) {
                img_skeletons += skeleton;
                i--;
            }
        }

        // inserts just inside the `result_container`, after its last child.
        result_container.insertAdjacentHTML('beforeend', img_skeletons);
    }

    function show_placeholder_in_skeleton() {
        var placeholders = document.querySelectorAll('.img-placeholder');
        var placeholder_count = placeholders.length;
        var show_sequential = function(placeholder, time) {
            // so much for not creating functions in a loop
            window.setTimeout(function() {
                placeholder.classList.add('show');
            }, time);
        }
        var timeoutId = window.setTimeout(function() {
            for (var i = 0; i != placeholder_count; i++) {
                show_sequential(placeholders[i], 150 * i);
            }
        }, 500);

        return timeoutId;
    }

    function get_results(search_term) {
        // returns 35 each time
        // see https://github.com/github/fetch for fetch() docs
        return fetch('https://bqdw8wrg6d.execute-api.us-east-1.amazonaws.com/prod/search_images?search_term=' + search_term + '&offset=' + search_offset);
    }

    function show_hidden_results() {
        var hidden_class = 'hidden-result';
        var hidden_img = document.querySelectorAll(hidden_class);
        var hidden_img_count = hidden_img.length;

        for (var i = 0; i != hidden_img_count; i++) {
            hidden_img[i].classList.remove(hidden_class);
        }
    }

    function clear_skeletons() {
        var skeleton_imgs = document.querySelectorAll('.skeleton-img');
        var skeleton_count = skeleton_imgs.length;

        for (var i = 0; i != skeleton_count; i++) {
            skeleton_imgs[i].remove();
        }
    }

    function show_results(results) {
        var skeleton_imgs = document.querySelectorAll('.skeleton-img');
        var skeleton_count = skeleton_imgs.length;
        var img_keys = Object.keys(results);

        if (img_keys.length >= img_at_a_time) {
            control_load_more('show');
        }
        else {
            control_load_more('hide');
        }
        for (var i = 0; i != skeleton_count; i++) {
            var img = results[img_keys[i]];
            if (img) {
                img.alt = img.width + ' x ' + img.height;
                img.detailsUrl = "https://commons.wikimedia.org/wiki/File:" + img.detailsUrl;
console.log(img);
                var imageHTML = render_template('ImgTpl', img);
                skeleton_imgs[i].classList.remove('skeleton-img');
                skeleton_imgs[i].innerHTML = imageHTML;
                if (i > img_at_a_time) {
                    // hide for later
                    skeleton_imgs[i].classList.add('hidden-result');
                }
            }
            else {
                clear_skeletons()
                break;
            }
        }
    }

    // TODO: when this is operational, make sure it doesn't clear the new
    /// images
    function clear_img(imgs) {
        var img_count = imgs.length;

        if (img_count) {
            for (var i = 0; i != img_count; i++) {
                imgs[i].remove();
            }
        }
    }

    function remove_page_breaks() {
        var page_breaks = document.querySelectorAll('.page-break');
        var breaks_count = page_breaks.length;

        for (var i = 0; i != breaks_count; i++) {
            page_breaks[i].remove();
        }
    }

    // get search results after click
    function search(search_term, type) {
        if (type === 'new') {
            var imgs = document.querySelectorAll('.img-container');
            clear_img(imgs);
            remove_page_breaks();
            search_offset = 0;
        }
        else {
            search_offset++;
            show_hidden_results();
        }

        control_load_more('hide');
        add_image_skeletons();
        var searchTimeoutId = show_placeholder_in_skeleton();
        get_results(search_term)
            .then(function(response) {
                window.clearTimeout(searchTimeoutId);
                return response.json();
            }).then(function(json) {
                var results = JSON.parse(json);

				if (location.protocol != 'file:') {
                    _paq.push(['trackSiteSearch',
                        // Search keyword searched for
                        search_term, // maybe add offset?
                        // Search category selected in your search engine. If you do not need this, set to false
                        false,
                        // Number of results on the Search results page. Zero indicates a 'No Result Search Keyword'. Set to false if you don't know
                        Object.keys(results.images).length,
                    ]);
                }

                if (results.success === false) {
                    flash_msg('Error retrieving images from Wikimedia Commons. Please try again.');
					clear_skeletons();

                    return;
				}
                // should check results.count instead of 'no-result'?
				else if (results.success === true && results.result === 'no-results') {
					flash_msg('No results for that search.');
                    clear_skeletons();
                    // hide up arrow

                    return;
				}
                // we got results
				else if (results.success === true && results.result === 'images') {
                    flash_msg('All resources below are from Wikimedia Commons. Click on the resource to see details + usage rights. To download, right click on "download" and click "Save Link As...".');
                    show_results(results.images);
                    if (results.images.length > img_at_a_time) {
                        control_load_more('show');
                    }
				}
            }).catch(function(ex) {
                flash_msg('Error retrieving images from Wikimedia Commons. Please try again.');
                console.log('fetch or parsing failed', ex);
            })
    }

    function insert_page_break() {
        var first_hidden_img = document.querySelector('.hidden-result');
        // we need a new one each time or it will reuse the same one
        var page_break = document.createElement('div');
        page_break.classList.add('page-break');
        result_container.insertBefore(page_break, first_hidden_img);
    }

    function listen_for_load_more() {
        load_more_el.addEventListener('click', function() {
            insert_page_break();
            // show hidden images
            var hidden_imgs = document.querySelectorAll('.hidden-result');
            var hidden_count = hidden_imgs.length;
            var search_term = get_search_term();

            // show hidden images
            for (var i = 0; i != hidden_count; i++) {
                hidden_imgs[i].classList.remove('hidden-result');
            }

            search(search_term, 'more');
        });
    }

    function submit() {
        var search_term = get_search_term();
        control_scroll_up('show');
        search(search_term, 'new');
    }

    function to_top() {
        window.animatedScrollTo(
            document.scrollingElement,
            0, // goes all the way back up. should show signup form when there.
            800
        );
    }

    return {
        submit: submit,
        search: search,
        flash_msg: flash_msg,
        to_top: to_top,
        listen_for_load_more: listen_for_load_more,
    }
}